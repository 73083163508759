/* src/components/FrontCover.css */
@import url('https://fonts.googleapis.com/css2?family=Boogaloo&family=Lobster&family=Montserrat:wght@300&family=Pacifico&family=Playball&family=Poetsen+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
    box-sizing: border-box;
  }

.front-cover-main-container {
  margin-bottom: 5rem;
}

.front-cover-container {
    /* position: relative; */
    width: 100%;
    height: 95vh;
    overflow: hidden;
    
  }
  
  .frontPic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
  }
  
  .title {
    font-family: 'Playball', cursive;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 3rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 1;
    width: 100%;
    font-weight: lighter;
  }
  
  .bottom-image-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40vh; /* Half of the viewport height */
    /* overflow: hidden; */
    
  }
  
  .LightPic {
    width: 99vw;
    height: 120%; /* Twice the container height to create a scroll effect */
    object-fit: contain;
    object-position: center bottom; /* Align the image to the bottom */
    z-index: 2;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .front-cover-main-container {
      margin-bottom: 2rem;
    }

    .bottom-image-container {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 30vh; /* Half of the viewport height */
      /* overflow: hidden; */
      
    }
    
    .LightPic {
      width: 99vw;
      height: 170%; /* Twice the container height to create a scroll effect */
      object-fit: contain;
      object-position: center bottom; /* Align the image to the bottom */
      z-index: 2;
      text-align: center;
    }

  }

  @media (max-width: 1020px) {
    .title {

      font-size: 2.5em;
      top: 30%;
      left: 50%;
      
    }

    .LightPic {
      width: 99vw;
      height: 130%; /* Twice the container height to create a scroll effect */

    }

  }