.footer-container {
    display: flex;
    width: 100%;
    margin-top: 2rem;
    background-color: black;
    color: white;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    flex: 0.8; /* Take 70% of the width */
    padding: 20px;
    font-size: 1.3em;
    font-family: 'Open Sans', sans-serif;
  }

  .footer-content h2 {
    font-family: 'Playball', cursive;
    font-size: 3rem;
  }

  .footer-content p:nth-child(2) {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 0.5rem;
  }
  
  .footer-content p:nth-last-child(1) {
    margin-top: 3rem;
    margin-bottom: 0;
  }

  .footer-map {
    display: flex;
    flex: 0.2;
    padding: 10px;
    padding-right: 50px;
    align-items: center;
  }

  .footer-map img {
    width: 80%;
    height: auto;
  }
  
  .description-list ul {
    list-style:square;
    padding: 10px;
  }
  
  .description-list li {
    margin: 5px 0;
  }


  @media (max-width: 1020px) {
    .footer-content {
        align-items: flex-start;
        flex: 0.6; /* Take 70% of the width */
        font-size: 1.2em;
      }
      
      .footer-map {
        flex: 0.4; /* Take 30% of the width */
      }

      .footer-map img{
        width: 100%;
      }
  }

  @media (max-width: 750px) {
    .footer-content {
        align-items: flex-start;
        flex: 0.7; /* Take 70% of the width */
        font-size: 1.2em
    
      }
      
      .footer-map {
        flex: 0.3; /* Take 30% of the width */
      }

      .footer-map img{
        width: 100%;
      }
  }
  